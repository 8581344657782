/** @format */
* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif !important;
}
body {
  background-color: #ffffff !important;
  overflow-x: hidden;
}

html,
body {
  overscroll-behavior-y: none;
}

img {
  object-fit: cover !important;
}

::-webkit-scrollbar {
  width: 0px;
  height: 6px;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  text-decoration: none !important;
}
// svg {
//   vertical-align: middle !important;
// }

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}
// Custom Scrollbar
.th-custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent;
}
.th-custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #8c8c8c;
}

.th-nowrap {
  white-space: nowrap;
}

.th-pointer {
  cursor: pointer;
}

.th-disabled {
  cursor: not-allowed !important;
}

.th-menu {
  // width: 290px;
  position: sticky;
  top: 0;
  height: 100vh;
  z-index: 200;
}
.submenu_bar {
  border-left: 1px #eaecf5 solid;
  width: 272px;
  box-shadow: rgba(0, 0, 0, 0.125) 1px 0px 1px 0px;
  position: relative;
  height: 100vh;
  background-color: #fff;
}

.profile_switch {
  position: absolute;
  bottom: 2%;
  left: 5%;
  right: 5%;
}
.th-fixed-lower {
  position: fixed !important;
  bottom: 0%;
  width: calc(100vw - 290px);
}
/* font color */
.th-black {
  color: #101828;
}

.th-black-1 {
  color: #1d2939 !important;
}

.th-black-2 {
  color: #344054 !important;
}
.th-black-3 {
  color: #101323 !important;
}

.th-grey {
  color: #868686 !important;
}

.th-grey-1 {
  color: #475467 !important;
}
.th-grey-2 {
  color: #667085 !important;
}
.th-grey-3 {
  color: #98a2b3 !important;
}
.th-grey-4 {
  color: #6a7692 !important;
}
.th-grey-5 {
  color: #98a2b3 !important;
}

.th-white {
  color: #ffffff !important;
}

.th-primary {
  color: #444ce7 !important;
}

.th-red {
  color: #f8222f !important;
}

.th-error {
  color: #d92d20 !important;
}

.th-green {
  color: #20c51c !important;
}

.th-green-1 {
  color: #039855 !important;
}

.th-holiday {
  color: #00b8df;
}

.th-events {
  color: #d700dd;
}

.th-green-2 {
  color: #3aac45;
}

.th-blue {
  color: #1b4ccb;
}

.th-blue-1 {
  color: #363f72;
}

.th-blue-2 {
  color: #717bbc;
}
.th-blue-3 {
  color: #293056;
}
.th-blue-4 {
  color: #4e5ba6;
}

.th-pink {
  color: #ff0d8f;
}

.th-error {
  color: #d92d20;
}

.th-yellow {
  color: #ff9922;
}

.th-yellow-1 {
  color: #f79009;
}

.th-violet {
  color: #ac00ea;
}

/* BG Color */
.th-bg-main {
  background-color: #f8f9fc !important;
}
.th-bg-black {
  background: #101828 !important;
}
.th-bg-white {
  background: #ffffff !important;
}
.th-bg-light {
  background: #f9fafb !important;
}
.th-bg-light-1 {
  background: #fcfcfd !important;
}

.th-bg-primary {
  background: #444ce7 !important;
}
.th-bg-primary-1 {
  background: #f5f8ff !important;
}

.th-bg-green {
  background: #20c51c;
}

.th-bg-green-1 {
  background: #ecfdf3;
}

.th-bg-green-3 {
  background: #039855;
}

.th-bg-red {
  background: #f8222f;
}

.th-bg-red-1 {
  background: #fef3f2;
}

.th-bg-grey {
  background-color: #eaecf5 !important;
}
.th-bg-grey-1 {
  background-color: #f2f4f7 !important;
}
.th-bg-grey-2 {
  background-color: #f8f9fc !important;
}
.th-bg-grey-3 {
  background-color: #d5d9eb !important;
}
.th-bg-error {
  background-color: #d92d20;
}
.th-bg-yellow {
  background-color: #fffde8 !important;
}
.th-bg-yellow-1 {
  background-color: #fffaeb !important;
}
.th-bg-pink {
  background-color: #edecf8 !important;
}
.th-bg-blue-1 {
  background-color: #d0e9f6 !important;
}
.th-bg-blue-2 {
  background-color: #cfe2ff !important;
}
.th-bg-blue-3 {
  background-color: #b0c4de !important;
}
.th-bg-blue-4 {
  background-color: #eef4ff !important;
}
.th-bg-blue-5 {
  background-color: #b3b8db !important;
}
.th-bg-blue-6 {
  background-color: #e0eaff !important;
}
.th-bg-blue-7 {
  color: #c7d7fe !important;
}
.th-bg-yellow-2 {
  background: #f0f1e1 !important;
}
.th-bg-yellow-3 {
  background: #ff9922 !important;
}
.th-bg-pink-2 {
  background-color: #ffdddd !important;
}
.th-bg-pink-3 {
  background-color: #d8d0f6 !important;
}
.th-bg-green-2 {
  background-color: #dedede !important;
}
.th-bg-violet {
  background-color: #9482da !important;
}
.th-bg-peach {
  background-color: #f9f6f6 !important;
}
